<template>
    <div class="user-center-layout">
        <UserSidebar/>
      <div class="main-content">
        <div class="header">
          <div class="header-title">
            <h4>我的购买</h4>
            <p>我已购买了 {{ favoriteCount }} 部作品</p>
          </div>
          <!-- <div class="actions">
            <div v-if="isEditing">
              <input type="checkbox" v-model="selectAll" @change="toggleSelectAll"> 全选
            </div>
            <el-button size="small" v-if="isEditing" type="warning" @click="deleteSelected">删除收藏</el-button>
            <el-button size="small" v-if="isEditing" @click="cancelEdit">取消</el-button>
            <div class="start-edit" v-else @click="startEdit">
              <img src="@/assets/edit.png" alt="整理收藏">
              <p>整理收藏</p>
            </div>
          </div> -->
        </div>
        <div class="favorite-list">
          <div 
            v-for="item in orderList" 
            :key="item.id" 
            class="favorite-item" 
            :class="{ selected: isSelected(item.id) }"
            @click="handleItemClick(item.id)"
          >
            <img :src="item.cover" :alt="item.title" style="width: 150px; height: 200px;">
            <p>{{ item.name }}</p>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import apiService from '@/api/api';
  import UserSidebar from '@/components/UserSidebar.vue';
  
  export default {
    name: 'UserOrderList',
    components: {
      UserSidebar
    },
    data() {
      return {
        isEditing: false,
        selectAll: false,
        selectedItems: [],
        orderList: [],
        total: 0,
        pageSize: 10,
        pageNum: 1
      };
    },
    mounted() {
      this.getFavorites();
    },
    computed: {
      favoriteCount() {
        return this.orderList.length;
      }
    },
    methods: {
      getFavorites() {
        const params = {
          pageSize: this.pageSize,
          pageNo: this.pageNum,
          type: 0
        };
        apiService.getBuyRecord2(params).then(response => {
          if (response.data.code === 0) {
            this.orderList = response.data.data.list;
          }
        });
      },
      startEdit() {
        this.isEditing = true;
      },
      cancelEdit() {
        this.isEditing = false;
        this.selectAll = false;
        this.selectedItems = [];
      },
      toggleSelectAll() {
        if (this.selectAll) {
          this.selectedItems = this.orderList.map(item => item.id);
        } else {
          this.selectedItems = [];
        }
      },
      isSelected(id) {
        return this.selectedItems.includes(id);
      },
      handleItemClick(id) {
        if (this.isEditing) {
          const index = this.selectedItems.indexOf(id);
          if (index > -1) {
            this.selectedItems.splice(index, 1);
          } else {
            this.selectedItems.push(id);
          }
        } else {
          // 跳转到漫画详情页
          this.$router.push(`/detail/${id}`);
        }
      },
      deleteSelected() {
        this.orderList = this.orderList.filter(item => !this.selectedItems.includes(item.id));
        this.cancelEdit();
      }
    }
  };
  </script>
  
  <style scoped>
  @import '@/assets/styles/usercenter.css';
  .user-center-layout{
  width: 70%;
}
  .favorite-list {
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
  }
  
  .favorite-item {
    width: 150px;
    margin: 10px;
    cursor: pointer;
    position: relative;
  }
  
  .favorite-item img {
    width: 100%;
    height: auto;
  }
  
  .favorite-item p {
    text-align: center;
    margin-top: 5px;
  }
  
  .favorite-item.selected::after {
    content: '✔';
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    padding: 5px;
    color: #ff6600;
  }
  </style>