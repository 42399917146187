<template>
  <div class="user-center-layout ">
    <AuthorSidebar />
    <div class="main-content">
      <div class="header-info">
        <h4>作者认证</h4>
        <p v-if="!author.isAuthor">通过填写更加详细的资料以及证明您是原创作者的作品，经过专业漫画编辑的审核后，您可成为北京漫星齐聚科技有限公司认证作者。认证作者将获得和参与北京漫星齐聚科技有限公司各项活动的基本条件之一，认证作者的作品将会，可以帮助我们联合作者参与各种赛事与您取得联系。</p>
        <p v-else-if="!author.isCertified">您的认证申请正在审核中，请耐心等待。</p>
        <p v-else>恭喜您已成为认证作者！</p>
        <p>有任何问题可以与我们联系 QQ：</p>
      </div>
      <div class="actions" v-if="author.isAuthor && !author.isCertified">
        <form @submit.prevent="submitForm" class="myform">
        <div class="form-group">
          <label for="name"><i>*</i>真实姓名：</label>
          <input type="text" id="name" v-model="formData.name" required>
        </div>
        <div class="form-group">
          <label for="cardId"><i>*</i>身份证号：</label>
          <input type="text" id="cardId" v-model="formData.cardId" required>
        </div>
        <div class="form-group">
          <label for="qq"><i>*</i>联系QQ：</label>
          <input type="text" id="qq" v-model="formData.qq" required>
        </div>
        <div class="form-group">
          <label for="bankName"><i>*</i>银行名称：</label>
          <input type="text" id="bankName" v-model="formData.bankName" required>
        </div>
        <div class="form-group">
          <label for="subBankName"><i>*</i>支行名称：</label>
          <input type="text" id="subBankName" v-model="formData.subBankName" required placeholder="请输入支行名称，详细到省市">
        </div>
        <div class="form-group">
          <label for="bankNo"><i>*</i>银行卡号：</label>
          <input type="text" id="bankNo" v-model="formData.bankNo" required>
        </div>
        <button type="submit" class="submit-button">确认</button>
      </form>

      </div>
    </div>
  </div>
</template>

<script>
import AuthorSidebar from '@/components/AuthorSidebar.vue';
import apiService from '@/api/api';
import { mapState } from 'vuex';

export default {
  name: 'AuthorCertification',
  components: {
    AuthorSidebar
  },
  computed: {
    ...mapState(['author'])
  },
  data() {
    return {
      formData: {
        name: '',
        cardId: '',
        qq: '',
        bankName: '',
        subBankName: '',
        bankNo: ''
      }
    };
  },
  mounted() {
    this.$store.dispatch('fetchAuthorStatus');
  },
  methods: {
    submitForm() {
      apiService.authorAuth(this.formData).then(res => {
        if (res.data.code === 0) {
          this.$notify.success('作者认证信息提交成功');
          // 可以在这里添加其他成功后的操作，比如跳转页面
        } else {
          this.$notify.error('作者认证信息提交失败，请重试');
        }
      }).catch(error => {
        console.error('提交作者认证信息时出错:', error);
        this.$notify.error('提交作者认证信息时出错，请重试');
      });
    }
  }
};
</script>

<style scoped>
@import '@/assets/styles/usercenter.css';


h4 {
  margin-bottom: 20px;
  color: #333;
}

.header-info {
  margin-bottom: 20px;
  color: #666;
  font-size: 12px;
  background-color: #fff;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
}

.form-group {
  margin-bottom: 15px;
}
.myform{
  width: 100%;
  padding:20px
}

label {
  display: block;
  margin-bottom: 5px;
  color: #333;
  font-size: 12px;
}

label i {
  color: red;
}

input[type="text"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
}

.submit-button {
  background-color: #00c853;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.submit-button:hover {
  background-color: #00a844;
}
</style>