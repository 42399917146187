<template>
  <div class="read-chapter">
    <div class="chapter-info">
      <!-- <div v-if="currentChapter.file" class="chapterImg">
        <img :src="currentChapter.file" alt="" srcset="">
      </div> -->
      <div ref="contentContainer" class="content-container">
        <div ref="chapterImg">
          <div class="chapterImg">
            <img :src="currentChapter.file" ref="chapterImgElement" />
          </div>
          <div class="moreTextName" @click="payMoreEvt">付费查看更多</div>
          <!-- <img src="../assets/more.jpg" alt="" srcset=""> -->
        </div>
      </div>
      <!-- <object v-if="currentChapter.file" :data="currentChapter.file" type="application/pdf" width="100%" height="1080px"></object> -->
    </div>
    <div class="chapter-list">
      <ul class="floating-chapter-list">
        <li v-for="(chapter, index) in chapterList" :key="chapter.id" class="chapter-item"
          @click="selectChapterFn(index)">
          {{ chapter.name }}
        </li>
      </ul>
    </div>

    <!-- 新增的购买章节弹窗 -->
    <el-dialog title="当前漫画付费" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <div class="dialog-content">
        <p>{{ selectChapter.name ? selectChapter.name : '当前章节' }}</p>
        <!-- <div class="coin-info">
          <span>账户金币: {{ userInfo ? userInfo.vo.gold : 0 }} 金币</span>
        </div>
 
        <div class="purchase-info"> -->
        <!-- <span class="coin-icon">⭐</span> -->
        <!-- <span>{{ selectChapter.icon ? selectChapter.icon : 0 }} 金币</span> -->
        <!-- <el-button v-if="selectChapter.icon && selectChapter.icon > userInfo.vo.gold" type="warning" size="small"
            @click="initialTab = 'coins'; showBuyVip = true">
            金币不足，去充值
          </el-button> -->
        <!-- v-else -->
        <!-- <el-button  v-if=" userInfo  && userInfo.vo.gold>0" type="primary" size="small" @click="handlePurchase" class="purchase-btn">金币兑换</el-button>
        </div> -->
        <div class="qr-code-section" v-if="showQrCode">
          <img ref="qrCodeImage" :src="qrCodeImage" alt="支付二维码">
          <div style="font-size: 24px;">支付宝扫码支付</div>
        </div>
        <!-- <el-checkbox v-model="autoNextChapter">
          自动购买下一章节
        </el-checkbox> -->
      </div>
    </el-dialog>
    <div v-if="showBuyVip">
      <el-dialog title="购买VIP" :visible.sync="showBuyVip" width="60%" center :before-close="handleCloseBuyService">
        <BuyService :initialTab="initialTab" />
      </el-dialog>
    </div>
    <LoginDialog :visible.sync="showLoginDialog" @login-success="onLoginSuccess" />
  </div>
</template>

<script>
import apiService from '@/api/api';
import BuyService from '@/components/BuyService.vue';
import LoginDialog from '@/components/LoginDialog.vue';
import QRCode from 'qrcode';
export default {
  name: 'ReadChapter',
  components: {
    BuyService,
    LoginDialog
  },
  data() {
    return {
      currentChapter: {
        file: null,
        id: null
      },
      chapterList: [],
      caricatureId: null,
      chapterId: null,
      userInfo: null,
      dialogVisible: false,
      autoNextChapter: false,
      selectChapter: {
        name: null,
        icon: null
      },
      showBuyVip: false,
      initialTab: 'vip',
      showLoginDialog: false,
      purchaseSuccess: false,
      showPurchasePrompt: false,
      accumulatedDistance: 0,
      qrCodeImage: '',
      showQrCode: false,
      selectWebPayOrderShow: false
    }
  },
  mounted() {
    this.caricatureId = this.$route.params.id;
    this.chapterId = this.$route.params.chapterId;
    this.fetchChapter(this.caricatureId, this.chapterId);
    this.getChapterList();
    this.checkCaricatureIsBuy()

    window.addEventListener('wheel', this.onWheel, { passive: true });
    window.addEventListener('scroll', this.onScroll, { passive: true });
  },
  beforeDestroy() {
    // 在组件销毁时移除事件监听
    window.removeEventListener('wheel', this.onWheel);
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    payMoreEvt() {
      let selectChapter = this.chapterList[0];
      let params = {
        caricatureId: Number(this.caricatureId),
        price: selectChapter.icon
      }
      console.log(params, 'good');
      this.dialogVisible = true
      let str = "购买了" + this.calculateCion(selectChapter.icon) + "张月票";
      this.buyApi(params, 'coin', str)
      this.selectChapterFn(0)
      this.dialogVisible = true
    },
    buyApi(params, qrcodeShowSrc, desc) {
      apiService.createOrder2(params).then(res => {
        if (res.data.code == 0) {
          //发起支付请求
          let data = {
            id: res.data.data.id,
            body: desc
          }
          this.fetchOrderStatus(res.data.data.id)
          apiService.payOrder(data).then(response => {
            if (response.data.code == 0) {
              this.generateQRCode(response.data.data.codeUrl, qrcodeShowSrc);

            } else {
              this.$notify({
                title: '失败',
                message: response.data.msg,
                type: 'error'
              });
            }
          })
        } else {
          console.log(res.data.msg);

          // alert(res.data.msg);
        }
      });
    },
    async generateQRCode(qrCodeUrl, qrcodeShowSrc) {
      const qrCodeDataUrl = await QRCode.toDataURL(qrCodeUrl)
      if (qrcodeShowSrc == 'coin') {
        this.qrCodeImage = qrCodeDataUrl
        this.showQrCode = true;
      }
    },
    fetchOrderStatus(orderId) {
      //轮询，每秒查询接口检测支付是否完成，完成的话弹窗以及关闭二维码显示
      //设置检查次数设定，如果超过次数，则弹窗提示支付失败
      let checkCount = 0;
      const maxCheckCount = 300; // 设置最大检查次数，例如30次
      const checkInterval = 1000; // 每次检查间隔1秒

      const checkPayStatus = () => {
        apiService.checkPayStatus(orderId).then(res => {
          if (res.data.code === 0) {
            if (res.data.data.status) {
              this.checkCaricatureIsBuy()
              this.dialogVisible = false;
              this.$notify({
                title: '成功',
                message: '支付成功',
                type: 'success'
              });
            } else {
              checkCount++;
              if (checkCount < maxCheckCount) {
                setTimeout(checkPayStatus, checkInterval);
              } else {
                this.$notify({
                  title: '失败',
                  message: '支付超时，请重试',
                  type: 'error'
                });
              }
            }
          } else {
            this.$notify({
              title: '错误',
              message: '检查支付状态失败',
              type: 'error'
            });
          }
        }).catch(error => {
          console.log(error);
          this.$notify({
            title: '错误',
            message: '检查支付状态出错',
            type: 'error'
          });
        });
      };

      checkPayStatus();
    },
    calculateCion(rmb) {
      return rmb * 10;
    },
    onWheel(event) {

      const imgElement = this.$refs.chapterImgElement;
      if (!imgElement) return;
      const imgHeight = imgElement ? imgElement.clientHeight : 0;
      const scrollDistance = event.deltaY;
      const threshold = imgHeight * 0.1;
      this.accumulatedDistance += scrollDistance
      let selectChapter = this.chapterList[0];
      if (selectChapter.isVip === 1 && this.selectWebPayOrderShow === false) {
        if (scrollDistance > 0 && !this.purchaseSuccess && this.accumulatedDistance > threshold) {
          let params = {
            caricatureId: Number(this.caricatureId),
            price: selectChapter.icon
          }
          console.log(params, 'good');
          this.dialogVisible = true
          let str = "购买了" + this.calculateCion(selectChapter.icon) + "张月票";
          this.buyApi(params, 'coin', str)
          this.selectChapterFn(0)
        }
      }

      if (!this.purchaseSuccess && imgHeight <= threshold) {
        console.log('1');
      }
      if (this.accumulatedDistance > imgHeight) {
        this.accumulatedDistance = imgHeight; // 防止累积超过图片高度
      }
    },
    onScroll() {
      const imgElement = this.$refs.chapterImgElement;
      if (!imgElement) return;
      const imgHeight = imgElement ? imgElement.clientHeight : 0;
      const scrollTop = window.scrollY || document.documentElement.scrollTop;

      // 处理滚动条拖动
      this.accumulatedDistance = scrollTop;
      let selectChapter = this.chapterList[0];
      if (selectChapter.isVip === 1 && this.selectWebPayOrderShow === false) {
        if (scrollTop > 0 && !this.purchaseSuccess && this.accumulatedDistance > imgHeight * 0.1) {
          let params = {
            caricatureId: Number(this.caricatureId),
            price: selectChapter.icon
          };
          this.dialogVisible = true;
          let str = "购买了" + this.calculateCion(selectChapter.icon) + "张月票";
          this.buyApi(params, 'coin', str);
          this.selectChapterFn(0);
        }
      }
    },
    fetchChapter(id, chapterId) {
      // 从API获取章节数据
      apiService.getCaricatureChapter(id, chapterId).then(response => {
        if (response.data.code == 0) {
          this.currentChapter = response.data.data;
          console.log("当前章节文件路径:", this.currentChapter.file);
        } else {
          this.$notify({
            title: '失败',
            message: '获取章节失败',
            type: 'error'
          });
        }
      }).catch(error => {
        console.error('获取章节失败:', error);
      });
    },
    getChapterList() {
      apiService.getCaricatureDetail(this.caricatureId).then(response => {
        if (response.data.code == 0) {
          this.chapterList = response.data.data.chapterItemVoList;
        }
      })
    },
    selectChapterFn(id) {
      let selectChapter = this.chapterList[id];
      this.selectChapter = selectChapter;

      this.needCoinBuy(selectChapter);
      //免费章节，直接阅读
      if (selectChapter.icon == null || selectChapter.icon == 0) {
        this.fetchChapter(this.caricatureId, selectChapter.id);
        return;
      }

      //检测是否需要使用金币购买
      if (selectChapter.icon && selectChapter.icon > 0) {
        this.needCoinBuy(selectChapter);
        return;
      } else {
        //检测是否是vip专属
        if (selectChapter.isVip) {
          //检测用户是否已登录
          if (!this.$store.state.isLoggedIn) {
            this.showLoginDialog = true;
            return;
          }
          //获取用户信息
          this.getUserInfo();
          //检测用户的vip是否到期
          if (this.checkVipIsExpired(this.userInfo.vip)) {
            alert('您的vip已到期，请先充值');
            return;
          } else {
            //直接进行查看
            this.fetchChapter(this.caricatureId, selectChapter.id);
          }

        } else {
          //直接进行查看
          this.fetchChapter(this.caricatureId, selectChapter.id);
        }
      }
    },
    checkCaricatureIsBuy() {
      let data = {
        caricatureId: this.caricatureId,
      }
      return new Promise((resolve, reject) => {
        apiService.selectWebPayOrder(data).then(response => {
          if (response.data.code == 0) {
            this.selectWebPayOrderShow = response.data.data
            // resolve(response.data.data);
          } else {
            resolve(false);
          }
        }).catch(error => {
          console.error('获取章节失败:', error);
          reject(error);
        });
      });
    },
    getUserInfo() {
      apiService.getUserInfo().then(response => {
        if (response.data.code == 0) {
          this.userInfo = response.data.data;
        }
      }).catch(error => {
        console.error('获取用户信息失败:', error);
      });
    },
    checkVipIsExpired(vip) {
      let now = new Date();
      let expired = new Date(vip.expiredTime);
      return now > expired;
    },
    //需要使用金币购买
    needCoinBuy(chapter) {
      //检测用户是否已登录
      if (!this.$store.state.isLoggedIn) {
        this.showLoginDialog = true;
        return;
      }

      //检测用户信息是否获取
      if (!this.userInfo) {
        this.getUserInfo();
      }
      //检测用户是否已购买
      this.checkCaricatureIsBuy(chapter.id).then(isBought => {
        if (isBought) {
          this.fetchChapter(this.caricatureId, chapter.id);
        } else {
          if (this.autoNextChapter) {
            //直接购买下一章节
            this.handlePurchase();
          } else {
            //显示购买弹窗
            this.dialogVisible = true;
          }
        }
      });
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(() => {
          done();
          this.checkCaricatureIsBuy()
        })
        .catch(() => { });
    },
    handleCloseBuyService(done) {
      this.$confirm('确认关闭？')
        .then(() => {
          //重新获取用户信息
          this.getUserInfo();
          done();
        })
        .catch(() => { });
    },
    handlePurchase() {
      //处理购买逻辑
      let data = {
        caricatureId: this.caricatureId,
        chapterId: this.selectChapter.id,
        coin: this.selectChapter.icon
      }
      apiService.buyCaricatureChapter(data).then(response => {
        if (response.data.code == 0) {
          this.$notify({
            title: '成功',
            message: '购买章节成功',
            type: 'success'
          });
          this.fetchChapter(this.caricatureId, this.selectChapter.id);
          this.dialogVisible = false;
        } else {
          this.$notify({
            title: '失败',
            message: response.data.msg,
            type: 'error'
          });
        }
      }).catch(error => {
        console.log(error, '-----------------');

        this.$notify({
          title: '失败',
          message: '购买章节失败',
          type: 'error'
        });
        console.error('购买章节失败:', error);
      });
    },
    onLoginSuccess() {

      this.getUserInfo();
    },

  },
}
</script>

<style scoped>
.read-chapter {
  position: relative;
}

.chapter-list {
  position: fixed;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  max-height: 80vh;
  overflow-y: auto;
}

.chapter-list ul {
  text-align: center;
  padding: 0 10px
}

.chapter-item {
  cursor: pointer;
  margin-bottom: 5px;
  padding: 5px;
  border-radius: 3px;
  transition: background-color 0.3s;
  border-bottom: 1px solid #e0e0e0;
  text-align: center;
}

.chapter-item:last-child {
  border-bottom: none;
}


.dialog-content {
  text-align: start;
}

.coin-info {
  margin: 20px 0;
  font-size: 14px;
  border-bottom: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  padding-bottom: 10px;
  padding-top: 20px;
}

.purchase-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.purchase-btn {
  background-color: #7fc63a;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
}

.purchase-info span {
  font-size: 20px;
  font-weight: bold;
  color: #f0ad4e;
}

.coin-icon {
  font-size: 20px;
  margin-right: 5px;
  color: #f0ad4e;
}

.el-checkbox {
  margin-top: 10px;
}

.content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.chapterImg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.moreTextName {
  font-size: 22px;
  text-align: center;
  margin-bottom: 20px;
  background-color: antiquewhite;
  color: black;
  padding-top: 20px;
  padding-bottom: 20px;
}
</style>