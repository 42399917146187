<template>
  <div class="detail-page">
    <div class="manga-info">
      <div class="manga-cover">
        <img :src="manga.cover" :alt="manga.title">
      </div>
      <div class="manga-details">
        <h1>{{ manga.name }}</h1>
        <!-- <p class="author">{{ manga.author }} | {{ manga.status }}</p> -->
        <p class="tags">
          <span v-for="tag in manga.tags" :key="tag" class="tag">{{ tag }}</span>
        </p>
        <p class="stats">
          <span>收藏: {{ manga.favorites ? manga.favorites : 0 }}</span>
          <span>人气: {{ manga.popularity ? manga.popularity : 0 }}</span>
        </p>
        <p class="description">{{ manga.descr }}</p>
        <div class="action-buttons">
          <button class="start-reading" @click="startReading">开始阅读</button>
          <button :class="{ 'add-favorite': !isFavorite, 'remove-favorite': isFavorite }" @click="toggleFavorite">
            {{ isFavorite ? '取消收藏' : '收藏' }}
          </button>
          <button class="vote" @click="donateTicket">投月票({{ manga.ticket }})</button>
          <button class="donate">打赏({{ manga.coin }})</button>
        </div>
      </div>
    </div>

    <div class="chapter-list">
      <div class="chapter-header">
        <h2>全文观看</h2>
        <div class="chapter-order">
          <button :class="{ active: !reverseOrder }" @click="reverseOrder = false">正序</button>
          <button :class="{ active: reverseOrder }" @click="reverseOrder = true">倒序</button>
        </div>
      </div>
      <div class="chapter-list-content">
        <ul>
          <li class="chapter-item" v-for="chapter in this.manga.chapters" :key="chapter.id"
            @click="readChapter(chapter.id)">
            <a>{{ chapter.name }}</a>
            <span class="update-time">{{ chapter.updateTime }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="donate-products">
      <div class="header">
        <h3>用礼物支持大大</h3>
        <div class="balance">
          <span>金币余额: {{ userCoins }}</span>
          <span class="separator">|</span>
          <span @click="rechargeCoins">充值金币</span>
        </div>
      </div>
      <div>
        <ul class="product-list">
          <li v-for="product in donateProducts" :key="product.id" :class="{ active: selectedProduct === product.id }"
            @click="selectProduct(product.id)">
            <img :src="product.image" :alt="product.name">
            <div class="product-info">
              <span>{{ product.title }}</span>
              <span class="product-price">{{ product.price }}元</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="donate-container">
        <button class="donate-button" @click="submitDonation">我要打赏</button>
      </div>
    </div>
    <div class="comments-section">
      <h2>评论 ({{ manga.commentVos ? manga.commentVos.length : 0 }})</h2>
      <div class="comment-input">
        <div class="comment-input-box">
          <img src='@/assets/default-avatar.png' alt="用户头像" class="avatar">
          <input type="text" v-model="newComment" placeholder="请填写评论，文明发言" class="comment-box">
        </div>
        <div><button @click="submitComment" class="comment-button">发布评论</button></div>
      </div>
    </div>

    <div v-if="showBuyService" class="modal-overlay" @click.self="closeBuyService">
      <div class="modal-content">
        <BuyService @close="closeBuyService" />
      </div>
    </div>

    <div class="comments-list">
      <div class="comment-item" v-for="(comment, index) in manga.comments" :key="comment.id">
        <div class="comment-avatar"><img :src="comment.avatar ? comment.avatar : '@/assets/default-avatar.png'"
            alt="用户头像" class="avatar"></div>
        <div class="comment-content">
          <div class="comment-header comment-content-item">
            <span class="username">{{ comment.username || '匿名' }}</span>
          </div>
          <div class="comment-content-item"><span class="comment-time">{{ comment.createTime }}</span></div>
          <div class="comment-content-item">
            <p class="comment-text">{{ comment.text }}</p>
          </div>
          <!-- <div class="comment-actions comment-content-item">
            <img src="@/assets/good.png" alt="点赞" class="replay-icon" @click="likeComment(comment.id)">
            <img src="@/assets/replay.png" alt="点赞" class="replay-icon" @click="toggleReply(index)">
          </div> -->
          <div v-show="comment.showReply" class="reply-input">
            <input type="text" v-model="comment.replyText" placeholder="请填写评论，文明发言" class="comment-box">
            <button @click="submitReply(index)" class="comment-button">发布评论</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiService from '@/api/api';
import BuyService from '@/components/BuyService.vue'; // 确保正确导入 BuyService 组件

export default {
  name: 'DetailPage',
  components: {
    BuyService // 注册 BuyService 组件
  },
  data() {
    return {
      manga: {
        id: 1,
        title: '田螺姑娘',
        author: '烟雨江南',
        status: '连载中',
        cover: 'path/to/cover.jpg',
        tags: ['冒险', '搞笑', '恋爱', '生活', '都市'],
        favorites: 1,
        popularity: 186,
        description: '田螺姑娘穿越现世找相公啦！什么，我的相公怎么是个女的！这让奴家如何是好!嘤嘤嘤！',
        votes: 0,
        donations: 1,
        commentCount: 0,
        chapters: [
          { id: 1, title: '第一话', url: '#', updateTime: '2023-05-01' },
          { id: 2, title: '第二话', url: '#', updateTime: '2023-05-08' },
          { id: 3, title: '第三话', url: '#', updateTime: '2023-05-15' },
          { id: 4, title: '第四话', url: '#', updateTime: '2023-05-22' },
          { id: 5, title: '第五话', url: '#', updateTime: '2023-05-29' },
        ]
      },
      reverseOrder: false,
      newComment: '',
      donateProducts: [],
      selectedProduct: null,
      userCoins: 0,
      showBuyService: false, // 添加 showBuyService
      userInfo: {},
      isFavorite: false,
    }
  },
  computed: {
    orderedChapters() {
      return this.reverseOrder ? [...this.manga.chapters].reverse() : this.manga.chapters;
    }
  },
  mounted() {
    this.fetchMangaDetails();
    this.fetchDonateProducts();
  },
  methods: {
    getUserDetail() {
      apiService.getUserInfo().then(response => {
        if (response.data.code == 0) {
          this.userInfo = response.data.data;
        }
      }).catch(error => {
        console.error('获取用户详情失败:', error);
      });
    },
    startReading() {
      //获取第一章节
      let tmpChapter = this.manga.chapters[0];
      this.readChapter(tmpChapter.id, tmpChapter.isVip);
    },
    readChapter(chapterId, isVip) {
      if (isVip) {
        // 检查用户是否已登录
        if (!this.$store.state.isLoggedIn) {
          this.$notify({
            title: '提示',
            message: '请先登录后再阅读',
            type: 'warning'
          });
          return;
        }
        this.getUserDetail();
        // 检查用户的vip是否到期
        // if (!this.checkVipIsExpired(this.userInfo.vip)) {
        //   // alert('您的vip已到期，请先充值');
        //   this.$notify({
        //     title: '提示',
        //     message: '您的vip已到期，请先充值',
        //     type: 'warning'
        //   });
        //   return;
        // } else {
          this.$router.push({
            path: '/read-chapter/:id/:chapterId',
            name: 'ReadChapter',
            params: { id: this.manga.id, chapterId: chapterId }
          });
        // }
      } else {
        if (!this.$store.state.isLoggedIn) {
          this.$notify({
            title: '提示',
            message: '请先登录后再阅读',
            type: 'warning'
          });
          return;
        }
        this.$router.push({
          path: '/read-chapter/:id/:chapterId',
          name: 'ReadChapter',
          params: { id: this.manga.id, chapterId: chapterId }
        });
      }
    },
    checkVipIsExpired(expiredTime) {
      if (expiredTime) {
        const currentTime = new Date().getTime();
        const expiredDate = new Date(expiredTime.endTime).getTime();
        if (expiredDate > currentTime) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    getCaricatureIsFavorite(id) {
      apiService.checkCaricatureIsFavorite(id).then(response => {
        if (response.data.code === 0) {
          this.isFavorite = response.data.data;
        }
      }).catch(error => {
        console.error('获取漫画是否收藏失败:', error);
      });
    },
    async fetchDonateProducts() {
      await apiService.getGiftList().then(response => {
        this.donateProducts = response.data.data;
      }).catch(error => {
        console.error('获取打赏产品列表失败:', error);
      });
    },
    async fetchMangaDetails() {
      await apiService.getCaricatureDetail(this.$route.params.id).then(response => {
        this.manga = response.data.data;
        this.manga.chapters = response.data.data.chapterItemVoList;
        let tmpComments = response.data.data.commentVos;
        tmpComments.forEach(comment => {
          this.$set(comment, 'showReply', false); // 确保每个评论对象都包含 showReply 属性
        });
        this.manga.comments = tmpComments;
        this.getCaricatureIsFavorite(this.manga.id);
      }).catch(error => {
        console.error('获取漫画详情失败:', error);
      });
    },
    toggleFavorite() {
      let type = 1;
      if (this.isFavorite) {
        type = 0;
      }
      apiService.postFlow({
        caricatureId: this.manga.id,
        title: this.manga.name,
        cover: this.manga.cover,
        type: type
      }).then(response => {
        // console.log(response)
        if (response.data.code === 0) {
          this.isFavorite = !this.isFavorite;
          if (type == 1) {
            this.manga.favorites += 1;
            this.$notify({
              title: '提示',
              message: '收藏成功',
              type: 'success'
            });
          } else {
            if (this.manga.favorites > 0) {
              this.manga.favorites -= 1;
            }
            this.$notify({
              title: '提示',
              message: '取消收藏成功',
              type: 'success'
            });
          }
        } else {
          this.$notify({
            title: '提示',
            message: '请先登录后再收藏',
            type: 'warning'
          });
        }

      });
    },
    donateTicket() {
      apiService.postDonateTicket({
        caricatureId: this.manga.id,
        total: 1
      }).then(response => {
        if (response.data.code == 0) {
          this.manga.ticket += 1;
          this.$notify({
            title: '提示',
            message: '投月票成功',
            type: 'success'
          });
        } else {
          this.$notify({
            title: '失败',
            message: response.data.msg,
            type: 'error'
          });
        }
      });
    },
    submitComment() {
      if (this.newComment.trim()) {
        apiService.postUserComment({
          caricatureId: this.manga.id,
          title: this.manga.name,
          text: this.newComment,
          parantId: null,
          authorId: this.manga.userId
        }).then(response => {
          console.log(response)
          if (response.data.code == 0) {
            this.manga.commentCount += 1;
            this.newComment = '';
            this.$notify({
              title: '提示',
              message: '评论成功',
              type: 'success'
            });
            // this.manga.comments.push({
            //   id: response.data.data.id,
            //   username: response.data.data.username,
            //   avatar: response.data.data.avatar,
            //   text: this.newComment,
            //   time: new Date().toLocaleString(),
            //   showReply: false
            // });
            this.fetchMangaDetails();
          }
        }).catch(error => {

          this.$notify({
            title: '失败',
            message: error.response.data.msg,
            type: 'error'
          });
        });
      }
    },
    toggleReply(index) {
      console.log(this.manga.comments);
      this.manga.comments[index].showReply = !this.manga.comments[index].showReply;
    },
    submitReply(index) {
      this.manga.comments[index].showReply = !this.manga.comments[index].showReply;
      let comment = this.manga.comments[index]
      apiService.postUserComment({
        caricatureId: this.manga.id,
        title: this.manga.name,
        text: comment.replyText,
        parantId: comment.id
      }).then(response => {
        console.log(response)
        this.manga.commentCount += 1;
        comment.replyText = '';
        // alert('发布成功');
        this.$notify({
              title: '提示',
              message: '发布成功',
              type: 'success'
            });
      }).catch(error => {
        console.error('发布评论失败:', error);
      });
    },
    submitDonation() {
      if (this.selectedProduct) {
        apiService.postDonate({
          caricatureId: this.manga.id,
          id: this.selectedProduct,
          total: 1
        }).then(response => {
          if (response.data.code == 0) {
            this.$notify({
              title: '提示',
              message: '打赏成功',
              type: 'success'
            });
          } else {
            this.$notify({
              title: '失败',
              message: response.data.msg,
              type: 'error'
            });
          }
        }).catch(error => {
          console.error('打赏失败:', error);
        });
      } else {
        this.$notify({
          title: '失败',
          message: '请选择打赏礼物',
          type: 'error'
        });
      }
    },
    selectProduct(productId) {
      this.selectedProduct = productId;
    },
    rechargeCoins() {
      this.showBuyService = true; // 修改 showBuyService 的值
    },
    closeBuyService() {
      this.showBuyService = false; // 添加关闭方法
    }
  }
}
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.separator {
  margin: 10px;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
}

.product-list li {
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.product-list li.active {
  border: 2px solid #7fc63a;
}

.product-list img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.product-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-info span:first-child {
  margin: 5px 0;
  color: #8a8a8a;
}

.product-price {
  color: #7fc63a;
  font-size: 15px;
}


.chapter-list {
  margin-bottom: 30px;
}

.chapter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.chapter-order button {
  padding: 5px 10px;
  margin-left: 10px;
  border: 1px solid #ddd;
  background-color: white;
  cursor: pointer;
}

.chapter-list-content {
  display: flex;
  flex-wrap: wrap;
}

.chapter-list-content ul {
  list-style: none;
}

.chapter-item {
  position: relative;
  float: left;
  height: 22px;
  margin-bottom: 10px;
  padding: 10px 15px 10px 15px;
  font-size: 14px;
  line-height: 22px;
  background: #fbfbfb;
  border-radius: 2px;
  width: 132px;
  margin-right: 10px;
  text-align: center;
}

.chapter-item:hover {
  cursor: pointer;
  color: #7fc63a;
}

.chapter-item a {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.donate-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.donate-button {
  width: 30%;
  height: 40px;
  margin: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  color: white;
  background-color: #7fc63a;
}

.comment-input-box {
  display: flex;
  align-items: start;
}

.comment-input-box img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.comment-box {
  background: #ffffff;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px 40px 40px 40px;
  padding: 12px;
  width: 100%;
  height: 60px;
  resize: none;
  overflow: auto;
  border: none;
  font-size: 14px;
  outline: none;
}

.comment-box:focus {
  border: 1px solid #7fc63a;
}

.comment-button {
  margin-left: auto;
  margin-right: 10px;
  margin-top: 10px;
  padding: 8px 15px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  background-color: #7fc63a;
  color: white;
  float: right;
}

.comments-list {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
}

.comment-item {
  display: flex;
  flex-direction: row;
  align-items: start;
  font-size: 12px;
}

.comment-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.comment-content {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.comment-content-item {
  margin-bottom: 10px;
}

.replay-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.replay-icon:hover {
  cursor: pointer;
  color: #7fc63a;
}

.comment-actions {
  display: flex;
  align-items: center;
}

.reply-input {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.detail-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.manga-info {
  display: flex;
  margin-bottom: 30px;
}

.manga-cover img {
  width: 200px;
  height: auto;
  object-fit: cover;
  margin-right: 20px;
}

.manga-details h1 {
  margin: 0 0 10px 0;
  color: #333;
}

.author,
.tags,
.stats {
  margin-bottom: 10px;
  color: #666;
}

.tag {
  background-color: #f0f0f0;
  padding: 2px 8px;
  margin-right: 5px;
  border-radius: 3px;
  font-size: 12px;
}

.description {
  margin-bottom: 20px;
  line-height: 1.6;
}

.action-buttons button {
  margin-right: 10px;
  padding: 8px 15px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
}

.start-reading {
  background-color: #7fc63a;
  color: white;
}


.chapter-order button.active {
  background-color: #f0f0f0;
}


.update-time {
  color: #999;
  font-size: 12px;
}

.comments-section {
  margin-top: 30px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  width: 100%;
}
</style>