import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/api/api'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoggedIn: false,
    author: {
      isAuthor: false,
      isCertified: false
    },
    bannerInputValue: ''
  },
  mutations: {
    setLoginStatus(state, status) {
      state.isLoggedIn = status
    },
    setAuthorStatus(state, {isCertified }) {
      state.author.isAuthor = true
      state.author.isCertified = isCertified
    },
    setBannerInputValue(state, value) {
      state.bannerInputValue = value;
    }
  },
  actions: {
    login({ commit }, { username, password }) {
      return api.login(username, password).then(response => {
        if (response.data.code === 0) {
          localStorage.setItem('token', response.data.data)
          commit('setLoginStatus', true)
          return api.getUserInfo()
        }
        return Promise.reject(response.data.msg)
      }).then(response => {
        if (response.data.code === 0) {
          commit('setUser', response.data.data)
        }
        return Promise.resolve()
      })
    },
    logout({ commit }) {
      localStorage.removeItem('token')
      commit('setLoginStatus', false)
      commit('setUser', null)
    },
    checkLoginStatus({ commit }) {
      const token = localStorage.getItem('token')
      if (token) {
        commit('setLoginStatus', true)
        return api.getUserInfo().then(response => {
          if (response.data.code === 0) {
            commit('setUser', response.data.data)
          }
        })
      }
    },
    // 这里可以添加一个 action 来从后端获取作者状态
    async fetchAuthorStatus({ commit }) {

      // 假设这是一个 API 调用
      api.getAuthorReview().then(response => {
        if(response.data.data==0){
          commit('setAuthorStatus', {isCertified: false})
        }else if(response.data.data==1){
          commit('setAuthorStatus', {isCertified: true})
        }else{
          commit('setAuthorStatus', {isCertified: false})
        }
      })
    },

    updateBannerInputValue({ commit }, value) {
      commit('setBannerInputValue', value);
    }
  },
  getters: {
    bannerInputValue: (state) => state.bannerInputValue
  }
})