<template>
  <div id="app">
    <Banner />
    <div class="content-wrapper">
      <router-view/>
    </div>
    <Footer />
  </div>
</template>

<script>
import Banner from '@/components/Banner.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    Banner,
    Footer,
  }
}
</script>

<style>
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}
ul{
  list-style: none;
}
li{
  list-style: none;
}
a{
  text-decoration: none;
  outline: none;
  color: #333;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  position: relative;
  padding-bottom: 117px; /* 确保有足够的空间容纳页脚 */
  box-sizing: border-box;
}

.content-wrapper {
  padding-bottom: 20px; /* 为页脚留出一些空间 */
  padding-top: 100px;
  box-sizing: border-box;
}
.el-notification{
  margin-top: 100px;
}
</style>  