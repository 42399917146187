<template>
  <div class="user-center-layout">
    <UserSidebar/>
    <div class="main-content">
      <div class="user-info">
        <div class="avatar-section">
          <img src="@/assets/default-avatar.png" alt="用户头像" class="avatar">
          <button class="vip-button" @click="initialTab = 'vip'; showBuyVip = true">购买VIP</button>
        </div>
        <div class="info-section">
          <div class="vip-info">VIP到期时间: {{ vipExpiredTime }}</div>
          <div class="currency-info">
            <span class="currency-item">
              <img src="@/assets/money.png" alt="金币" class="currency-icon">
              {{ getGoldAmount(userInfo) }} 金币
            </span>
            <span class="currency-item">
              <img src="@/assets/ticket.png" alt="月票" class="currency-icon">
              {{ getTicketAmount(userInfo) }} 月票
            </span>
          </div>
        </div>
      </div>
      <div class="tab-content">
        <el-tabs v-model="activeTab">
          <el-tab-pane label="金币" name="coins-user">
            <div class="balance">
              <div class="balance-left">
                <p>余额: {{ getGoldAmount(userInfo) }} 金币</p>
                <!-- <a href="#" class="recharge-link">查看消费记录 ></a> -->
              </div>
              <el-button size="small" type="primary" class="buy-vip-btn" @click="initialTab = 'coins'; showBuyVip = true">充值</el-button>
            </div>
            <div class="balance">
              <p>金币怎么花?</p>
            </div>
            <div class="usage-options">
              <div class="option">
                <div class="option-content">
                  <span>1</span>
                  <p>购买VIP</p>
                </div>
                <div><p>尊享会员特权</p></div>
                <div><img src="@/assets/vip-icon.png" alt="购买VIP"></div>
              </div>
              <div class="option">
                <div class="option-content">
                  <span>2</span>
                  <p>打赏礼物</p>
                </div>
                <div><p>为作者大大加油打气</p></div>
                <div><img src="@/assets/gift-icon.png" alt="打赏礼物"></div>
              </div>
              <div class="option">
                <div class="option-content">
                  <span>3</span>
                  <p>购买章节</p>
                </div>
                <div><p>精彩不容错过</p></div>
                <div><img src="@/assets/chapter-icon.png" alt="购买章节"></div>
              </div>
              <div class="option">
                <div class="option-content">
                  <span>4</span>
                  <p>购买月票</p>
                </div>
                <div><p>为爱投票</p></div>
                <div><img src="@/assets/ticket-icon.png" alt="购买月票"></div>
              </div>
            </div>
            <div>
              <div class="balance">
              <p>金币是什么?</p>
            </div>
            <ol class="description-list">
              <li>金币是网站推出的虚拟货币，用户可以通过充值获得;</li>
                <li>金币可用于购买付费章节、打赏礼物等。</li>
              </ol>
            </div>
          </el-tab-pane>
          <el-tab-pane label="月票" name="tickets-user">
            <!-- 月票内容 -->
            <div class="balance">
              <div class="balance-left">
                <p>余额: {{ calculateTickets(userInfo) }} 月票</p>
                <!-- <a href="#" class="recharge-link">查看消费记录 ></a> -->
              </div>
            </div>
            <h3 class="section-title">月票是什么?</h3>
            <ol class="description-list">
              <li>月票是网站推出的虚拟货币，用户可以通过充值获得;</li>
              <li>月票可用于购买付费章节、打赏礼物等。</li>
            </ol>
          </el-tab-pane>
          <el-tab-pane label="VIP" name="vip-user">
            <!-- VIP内容 -->
            <div class="vip-content">
              <div class="vip-content-title">
                <div>
                  <h3 class="vip-title">尊享VIP</h3>
                  <p class="vip-subtitle">享受尊贵权益 畅读精彩内容</p>
                </div>
                <div>
              <el-button type="primary" class="buy-vip-btn" @click="initialTab = 'vip'; showBuyVip = true">立即开通</el-button>
                </div>
              </div>
              <div class="vip-benefits">
                <div class="benefit-item">
                  <img src="@/assets/vip-icon1.png" alt="尊贵标识" class="benefit-icon">
                  <p class="benefit-text">尊贵标识<br>VIP专属标识</p>
                </div>
                <div class="benefit-item">
                  <img src="@/assets/vip-icon2.png" alt="免广告阅读" class="benefit-icon">
                  <p class="benefit-text">免广告阅读<br>沉浸式体验</p>
                </div>
                <div class="benefit-item">
                  <img src="@/assets/vip-icon3.png" alt="每日免费" class="benefit-icon">
                  <p class="benefit-text">每日免费<br>畅读多话</p>
                </div>
                <div class="benefit-item">
                  <img src="@/assets/vip-icon4.png" alt="高速缓存" class="benefit-icon">
                  <p class="benefit-text">高速缓存<br>VIP专属通道</p>
                </div>
                <div class="benefit-item">
                  <img src="@/assets/vip-icon5.png" alt="专属客服" class="benefit-icon">
                  <p class="benefit-text">专属客服<br>贴心服务</p>
                </div>
                <div class="benefit-item">
                  <img src="@/assets/vip-icon6.png" alt="优先更新" class="benefit-icon">
                  <p class="benefit-text">优先更新<br>抢先体验</p>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div v-if="showBuyVip">
      <el-dialog
        title="购买"
        :visible.sync="showBuyVip"
        width="40%"
        center
        :before-close="handleClose">
        <BuyService :initialTab="initialTab" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import UserSidebar from '@/components/UserSidebar.vue';
import apiService from '@/api/api';
import BuyService from '@/components/BuyService.vue';

export default {
  name: 'UserDetail',
  components: {
    UserSidebar,
    BuyService
  },
  data() {
    return {
      activeTab: 'coins-user',
      userInfo: {},
      vipExpiredTime: "",
      showBuyVip: false,
      initialTab: 'coins'
    }
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    calculateTickets(userInfo){
      if(userInfo.vo){
        if(userInfo.vo.ticket){
          return userInfo.vo.ticket;
        }else{
          return 0;
        }
      }else{
        return 0;
      }
    },
    getGoldAmount(userInfo){
      if(userInfo.vo){
        if(userInfo.vo.gold){
          return userInfo.vo.gold;
        }else{
          return 0;
        }
      }else{
        return 0;
      }
    },
    getTicketAmount(userInfo){
      if(userInfo.vo){
        if(userInfo.vo.ticket){
          return userInfo.vo.ticket;
        }else{
          return 0;
        }
      }else{
        return 0;
      }
    },
    handleClose() {
      this.showBuyVip = false;
    },
    getUserInfo() {
      apiService.getUserInfo().then(res => {
        if(res.data.code == 0){
          this.userInfo = res.data.data;
          this.vipExpiredTime = this.checkVipIsExpired(this.userInfo.vip);
        }
      });
    },
    checkVipIsExpired(expiredTime){
      if(expiredTime){
        const currentTime = new Date().getTime();
        const expiredDate = new Date(expiredTime.endTime).getTime();
      if (expiredDate > currentTime) {
        return expiredTime.endTime;
      } else {
        return "VIP已过期";
      }
      }else{
        return "体验已到期";
      }
    }
  }
}
</script>

<style scoped>
@import '@/assets/styles/usercenter.css';
.user-center-layout{
  width: 70%;
}
.user-info {
  display: flex;
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
}

.avatar-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
}

.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.vip-button {
  background-color: #7fc63a;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 15px;
  cursor: pointer;
}

.info-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
}

.vip-info {
  margin-bottom: 10px;
}

.currency-info {
  display: flex;
}

.currency-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.currency-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.tab-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
}

.balance {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.balance p{
  /* background-color: #7fc63a; */
  /* color: white; */
  border: none;
  padding: 5px 10px;
  /* border-radius: 15px; */
  cursor: pointer;
} 
.balance .el-button{
  background-color: #7fc63a;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 15px;
  cursor: pointer;
  width: 100px;
  height: 30px;
}

.section-title {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
}

.recharge-link {
  color: #7fc63a;
  text-decoration: none;
}

.usage-options {
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
}

.option {
  text-align: center;
    font-size: 12px;
    border-right: 1px solid #f4f4f4;
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.option:last-child {
  border-right: none;
  padding-right: 0px;
}

.option-content {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #666;
}

.option-content span{
  background-color: #7fc63a;
  color: white;
  border: none;
  padding: 1px 5px;
  border-radius: 15px;
  cursor: pointer;
}
.option img {
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
}

.description-list {
  padding-left: 20px;
  color: #666;
  font-size: 12px;
}

.vip-benefits {
  display: flex;
  align-items: center;
  padding-top: 20px;
}

.benefit-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  padding-right: 20px;
  border-right: 1px solid #f4f4f4;
  font-size: 12px;
  text-align: center;
}
.vip-content-title{
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}
.balance-left{
  display: flex;
  align-items: center;
}
.el-tabs__item.is-active {
  color: #7fc63a !important;
}

.el-tabs__active-bar {
  background-color: #7fc63a !important;
}
</style>