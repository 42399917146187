<template>
  <div class="all-rank-list">
    <div v-for="(rank, index) in ranks" :key="index" class="rank-section">
      <div class="rank-header">
        <h3>{{ titleList[rank.type] }}</h3>
        <p @click="goRankDetail(rank.type)">^更多^</p>
      </div>
      <ul>
        <li v-for="(item, itemIndex) in rank.rank" :key="itemIndex" @click="goToDetailPage(item.id)">
          <span class="rank-number">{{ itemIndex + 1 }}</span>
          <img :src="item.cover" :alt="item.title" class="rank-cover">
          <span class="rank-title">{{ item.name }}</span>
          <!-- <span class="rank-author">{{ item.author }}</span> -->
          <span class="rank-score">总点击：{{ item.num }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import apiService from '@/api/api';
export default {
  name: 'AllRankList',
  props: {
    rankTypeList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      ranks: [],
      titleList: []
    };
  },
  mounted() {
    this.fetchRankList();
  },
  methods: {
    fetchRankList() {
        const params = {
            type: 9
        };
      apiService.getRankList(params).then(response => {
        if(response.data.code == 0){
           this.ranks = response.data.data;
           //将对象的key和value转换成数组，并过滤掉value为空的项,将key作为type的值，将value作为rank的值
         this.ranks = Object.entries(response.data.data)
            .filter(([, value]) => value.length > 0)
            .map(([key, value]) => ({
              type: parseInt(key),
              rank: value
            }));
           for(let i = 0; i < this.rankTypeList.length; i++){
            this.titleList[this.rankTypeList[i].type] = this.rankTypeList[i].name;
           }  
        }
      });
    },
    goToDetailPage(id) {
      this.$router.push(`/detail/${id}`);
    },
    goRankDetail(type) {
      this.$emit('update-active-tab', type.toString());
      // this.$router.push(`/rank?type=${type}`);
    }
  },

};
</script>

<style scoped>
.all-rank-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 12px;
}

.rank-section {
  width: 30%;
  margin-bottom: 20px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}

.rank-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
}

.rank-header p {
  cursor: pointer;
  color: #7fc63a;
}

.rank-cover {
  width: 50px;
  height: 70px;
  object-fit: cover;
  margin-right: 10px;
}

.rank-number {
  margin-right: 10px;
  background-color: #7fc63a;
    color: white;
    border: none;
    padding: 1px 5px;
    border-radius: 15px;
    cursor: pointer;
}

.rank-title {
  flex-grow: 1;
}

.rank-author, .rank-score {
  margin-left: 10px;
}
.rank-score {
    color: #999;
}
</style>