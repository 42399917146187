import axios from 'axios';
import router from '@/router'; // 导入路由实例

// 创建axios实例
//http://6r6w2p.natappfree.cc 

const api = axios.create({
  // baseURL: 'http://192.168.3.19:48083', // 替换为您的实际API基础URL
  baseURL: 'https://api.bjyouliang.com/', // 替换为您的实际API基础URL
  timeout: 10000,
});

// 需要拦截的路由路径列表
const interceptedRoutes = ['/profile'];

// 请求拦截器
api.interceptors.request.use(
  (config) => {
    // 获取当前路由路径
    const currentPath = router.currentRoute.path;
    
    // 检查当前路径是否需要拦截
    const token = localStorage.getItem('token');
    
    if (token) {
      config.headers.Authorization = `${token}`;
    }
    
    // 根据请求内容类型设置不同的 Content-Type
    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data';
    } else {
      config.headers['Content-Type'] = 'application/json';
    }
    
    if (interceptedRoutes.some(route => currentPath.startsWith(route))) {
      if (!token) {
        // 如果用户未登录,重定向到登录页面
        router.push('/login');
        return Promise.reject('请先登录');
      }
    }
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // 如果收到未授权响应,清除token并重定向到登录页面
      localStorage.removeItem('token');
      router.push('/login');
    }
    return Promise.reject(error);
  }
);

export default api;