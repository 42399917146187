<template>
  <footer class="site-footer">
    <div class="footer-content">
      <div class="footer-links">
        <router-link to="/about?tab=about">关于我们</router-link>
        <router-link to="/about?tab=contact">联系我们</router-link>
        <!-- <router-link to="/about?tab=sitemap">网站地图</router-link> -->
        <router-link to="/about?tab=legal">法律声明</router-link>
        <router-link to="/about?tab=review">内容审核</router-link>
        <!-- <router-link to="/about?tab=sitemap">网站统计</router-link> -->
      </div>
      <div class="footer-info">
        统一社会信用码：91110112MADKLTLKXA &nbsp;
        <p>北京优量智选科技有限公司&nbsp;
          地址：北京市通州区潞城镇武兴路7号B2911室&nbsp;&nbsp;
          联系电话：13720084565&nbsp;
          </p>
          <p>网站备案号：京ICP备2024091684号-1</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
.site-footer {
  background-color: #ebf6fc;
  padding: 20px 0;
  font-size: 14px;
  color: #666;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.footer-links {
  margin-bottom: 15px;
  font-size: 12px;
}

.footer-links a {
  color: #666;
  text-decoration: none;
  margin: 0 10px;
}

.footer-links a:hover {
  color: #7fc63a;
}

.footer-info p {
  margin: 5px 0;
  font-size: 12px;
}
</style>