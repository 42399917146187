<template>
  <div class="user-center-layout">
    <UserSidebar />
    <div class="main-content">
      <div class="header">
        <div class="header-title">
        <h4 class="page-title">消费记录</h4>
      </div>
      <div class="filter-section">
        <span>选择时间</span>
        <el-select v-model="timeFilter" placeholder="全部" @change="handleFilterChange">
          <el-option label="全部" value="all"></el-option>
          <el-option label="最近一周" value="week"></el-option>
          <el-option label="最近一月" value="month"></el-option>
          <el-option label="最近三月" value="threeMonths"></el-option>
        </el-select>
      </div>
      </div>
      <div class="actions">
        <el-tabs v-model="activeTab" class="consume-tabs" @tab-click="handleTabClick">
        <el-tab-pane label="金币" name="coins">
          <el-table :data="coinsRecords" style="width: 100%">
            <el-table-column prop="text" label="详情" >
              <template slot-scope="scope">
                <span @click="goToDetail(scope.row.caricatureId)" style="cursor: pointer; color: #409EFF;">{{ scope.row.text }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="num" label="金额"></el-table-column>
            <el-table-column label="时间" >
              <template slot-scope="scope">
                {{ new Date(scope.row.createTime).toLocaleString() }}
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @current-change="handlePageChange"
            :current-page="currentPage"
            :page-size="pageSize"
            :total="total"
            layout="prev, pager, next"
            class="pagination"
          ></el-pagination>
        </el-tab-pane>
        <el-tab-pane label="月票" name="tickets">
          <el-table :data="ticketsRecords" style="width: 100%">
            <el-table-column prop="text" label="详情" >
              <template slot-scope="scope">
                <span @click="goToDetail(scope.row.caricatureId)" style="cursor: pointer; color: #409EFF;">{{ scope.row.text }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="num" label="数量" ></el-table-column>
            <el-table-column label="时间" >
              <template slot-scope="scope">
                {{ new Date(scope.row.createTime).toLocaleString() }}
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @current-change="handlePageChange"
            :current-page="currentPage"
            :page-size="pageSize"
            :total="total"
            layout="prev, pager, next"
            class="pagination"
          ></el-pagination>
        </el-tab-pane>
      </el-tabs>
      </div>

    </div>
  </div>
</template>

<script>
import UserSidebar from '@/components/UserSidebar.vue';
import { apiService } from '@/api/api';

export default {
  name: 'ConsumeRecordManager',
  components: {
    UserSidebar
  },
  data() {
    return {
      activeTab: 'coins',
      timeFilter: 'all',
      coinsRecords: [],
      ticketsRecords: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
      recordType: 0
    };
  },
  mounted() {
    this.fetchRecords();
  },
  methods: {
    changeTab(tab) {
      this.activeTab = tab;
      this.fetchRecords();
    },
    async fetchRecords() {
      try {
        if(this.activeTab === 'coins'){
            this.recordType = 0
        }else{
            this.recordType = 1
        }
        const response = await apiService.getConsumeRecord({
          pageNo: this.currentPage,
          pageSize: this.pageSize,
          type: this.recordType,
          timeFilter: this.timeFilter,
        });
        if (response.data.code === 0) {
          if (this.activeTab === 'coins') {
            this.coinsRecords = response.data.data.list;
          } else {
            this.ticketsRecords = response.data.data.list;
          }
          this.total = response.data.data.total;
        } else {
          console.error('获取消费记录失败:', response.data.msg);
        }
      } catch (error) {
        console.error('获取消费记录出错:', error);
      }
    },
    handlePageChange(newPage) {
      this.currentPage = newPage;
      this.fetchRecords();
    },
    handleTabClick() {
      this.currentPage = 1;
      this.fetchRecords();
    },
    handleFilterChange() {
      this.currentPage = 1;
      this.fetchRecords();
    },
    goToDetail(caricatureId) {
      this.$router.push({ path: '/detail', query: { id: caricatureId } });
    }
  }
};
</script>

<style scoped>
@import '@/assets/styles/usercenter.css';
.user-center-layout{
  width: 70%;
}
.page-title {
  font-size: 18px;
  color: #333;
  margin-bottom: 20px;
}

.filter-section {
    display: flex;
    align-items: center;
    font-size: 14px;
  }

.filter-section span {
  margin-right: 10px;
}

.consume-tabs {
  margin-top: 20px;
  width: 100%;
}

.el-tabs__item {
  font-size: 16px;
}

.el-tabs__item.is-active {
  color: #ff6600 !important;
}

.el-tabs__active-bar {
  background-color: #ff6600 !important;
}

.pagination {
  margin-top: 20px;
  text-align: center;
}
</style>