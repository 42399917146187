<template>
  <header class="banner">
    <div v-if="!isLoggedIn">
      <div class="banner-content">
        <!-- <div class="logo"> -->
        <!-- <img src="@/assets/logo.png" alt="Logo"> -->
        <!-- </div> -->
        <div class="logoName">
          优量漫画
        </div>
        <div class="logoCenter">
          <nav>
            <ul>
              <li><router-link to="/" exact>首页</router-link></li>
              <!-- <li v-for="category in categories" :key="category.id">
                <router-link :to="{ name: 'Category', params: { categoryId: category.id } }" class="category-item">
                  {{ category.name }}
                </router-link>
              </li> -->
              <!-- <li><router-link to="/update">更新</router-link></li> -->
              <!-- <li><router-link to="/category">分类</router-link></li> -->
              <li><router-link to="/rank">排行</router-link></li>

              <!-- <li><router-link to="/bookshelf">书架</router-link></li> -->
            </ul>
            <ul>

            </ul>
          </nav>
        </div>
        <div class="null"></div>
        <div class="searchBox">
          <el-input type="text" v-model="inputValue" placeholder="请输入关键字" class="search_input_l" clearable  />
          <div class="search_input_r" @click="updateInputValue"></div>
        </div>
        <!-- <div class="search-bar">
        <input type="text" placeholder="搜索漫画">
        <button>搜索</button>
      </div> -->
        <div class="user-info">
          <span class="user-avatar"></span>
          <router-link v-if="isLoggedIn" to="/user" class="user-name">{{ userName }}</router-link>
          <router-link v-if="!isLoggedIn" to="/login" class="login-btn">登录</router-link>
        </div>
      </div>
    </div>
    <div v-if="isLoggedIn">
      <div class="banner-content">
        <!-- <div class="logo">
          <img src="@/assets/logo.png" alt="Logo">
        </div> -->
        <div class="logoName">
          优量漫画
        </div>
        <div class="logoCenter">
          <nav>
            <ul>
              <li><router-link to="/" exact>首页</router-link></li>
              <!-- <li v-for="category in categories" :key="category.id">
                <router-link :to="{ name: 'Category', params: { categoryId: category.id } }" class="category-item">
                  {{ category.name }}
                </router-link>
              </li> -->
              <!-- <li><router-link to="/category">分类</router-link></li> -->
              <li><router-link to="/rank">排行</router-link></li>
              <!-- <li><router-link to="/update-user-info">账户设置</router-link></li> -->
            </ul>
          </nav>
        </div>
        <div class="null"></div>
        <div class="searchBox">
          <el-input type="text" v-model="inputValue" placeholder="请输入关键字" class="search_input_l" clearable />
          <div class="search_input_r" @click="updateInputValue"></div>
        </div>
        <!-- <div class="search-bar">
        <input type="text" placeholder="搜索漫画">
        <button>搜索</button>
      </div> -->
        <div class="user-info go-author">
          <div class="user-info-item">
            <p @click="goUserCenter">用户中心</p>
          </div>
          <!-- <div class="user-info-item">
            <p @click="goAuthorCenter">作者中心</p>
          </div> -->
          <div class="user-info-item-logout">
            <p @click="handleLogout">退出登录</p>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import apiService from '@/api/api';
export default {
  name: 'Banner',
  computed: {
    ...mapState(['isLoggedIn', 'user'])
  },
  data() {
    return {
      userName: '游客',
      isAuthor: false,
      userAvatar: '',
      categories: [],
      inputValue: ''
    }
  },
  methods: {
    updateInputValue() {
      this.$store.dispatch('updateBannerInputValue', this.inputValue);
    },
    ...mapActions(['logout', 'checkLoginStatus']),
    ...mapMutations(['setLoginStatus']),
    goAuthorCenter() {
      this.$router.push('/author-dashboard');
    },
    fetchCategories() {
      apiService.getCategory().then(response => {
        if (response.data.code === 0) {
          this.categories = response.data.data[0].category;
        }
      });

    },
    goUserCenter() {
      this.$router.push('/user');
    },
    handleLogout() {
      this.logout();
      this.$router.push('/');
    },
    async checkLoginStatus() {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await apiService.getUserInfo();
          if (response.data.code === 0) {
            this.setLoginStatus(true);
            this.userName = response.data.data.account || response.data.data.nickname;
            this.userAvatar = response.data.data.avatar;
          } else {
            this.setLoginStatus(false);
          }
        } catch (error) {
          console.error('获取用户信息失败:', error);
          this.setLoginStatus(false);
        }
      } else {
        this.setLoginStatus(false);
      }
    }
  },
  mounted() {
    this.checkLoginStatus();
    this.fetchCategories()
  }
}
</script>

<style scoped>
.go-author {
  cursor: pointer;
  color: white;
  padding: 5px 5px;
  text-decoration: none;
  border-radius: 3px;
  font-size: 12px;
}

.user-info-item {
  margin-right: 10px;
  cursor: pointer;
  background-color: #7fc63a;
  color: #f7f4f4;
  padding: 0 10px;
  text-decoration: none;
  border-radius: 3px;
  font-size: 12px;

}

.user-info-item-logout {
  margin-right: 10px;
  cursor: pointer;
  background-color: #e0101a;
  color: #f7f4f4;
  padding: 0 10px;
  text-decoration: none;
  border-radius: 3px;
  font-size: 12px;

}

.banner {
  font-family: Arial, sans-serif;
  background-color: #1e1e1e;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 8888;
  margin: 0 auto;
  width: 100%;
  top: 0;
  margin-bottom: 100px;
  /* left: 0; */
}

.banner-content {
  max-width: 1487px;
  margin: 0 auto;
  padding: 10px 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
}

.logoCenter {

  display: flex;
  justify-content: start;
}

.logoCenter nav {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #f7f4f4;
  justify-content: space-between;

  ul li a {
    text-decoration: none;
    color: #eadfdf;
  }

  /* ul li a:hover{
    text-decoration: none;
    color: #7fc63a;
} */
  ul li a.router-link-active {
    text-decoration: none;
    color: #7fc63a;
  }

}

.logo img {
  height: 40px;
}

.search-bar {
  position: relative;
  display: inline-block;
}

input[type="text"] {
  padding: 8px 70px 8px 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  /* 修改这里来调整圆角程度 */
  width: 250px;
  font-size: 12px;
}

button {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  background: #f0f0f0;
  border: none;
  border-radius: 4px;
  /* 给按钮也添加一点圆角 */
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  color: #333;
}

button:hover {
  background: #e0e0e0;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-avatar {
  width: 30px;
  height: 30px;
  background-color: #ddd;
  border-radius: 50%;
  margin-right: 10px;
}

.login-btn,
.logout-btn {
  background-color: #7fc63a;
  color: white;
  padding: 5px 10px;
  text-decoration: none;
  border-radius: 3px;
}


nav ul {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

nav ul li {
  padding: 10px 20px;
  font-size: 17px;
}

nav ul li a {
  text-decoration: none;
  color: #333;
}

nav ul li a.router-link-active {
  color: #7fc63a;
}

.logoName {
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-size: 22px;
  font-weight: 700;
  height: 100%;
  justify-content: center;
  margin-right: 40px;
  padding: 5px;
  width: 200px;
  color: aliceblue;
}

.null{
  width: 10%;
}
.searchBox {
  display: flex;
  flex: 1;
}

.search_input_l {

  height: 40px;
  width: 356px;
  border: none;
  color: #a9a9a9;
  padding-left: 10px;
}

.search_input_r {
  width: 66px;
  height: 40px;
  background: url(../assets/theme_tb.png) no-repeat -2px -19px;
  border: none;
  /* margin: 3px 3px 3px 0; */
  cursor: pointer;
}

:deep(.el-input__inner) {
  padding: 0 !important;
  padding-left: 10px !important;
  border-radius: 0 !important;
}
</style>