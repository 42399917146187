<template>
    <div>
      <el-table :data="orders" style="width: 100%">
        <el-table-column prop="code" label="订单编号" width="100"></el-table-column>
        <el-table-column prop="staticJson" label="订单详情" >
          <template v-slot="scope">
            {{ parseOrderDetail(scope.row.staticJson) }}
          </template>
        </el-table-column>
        <el-table-column prop="payChannel" label="支付方式" width="100">
              支付宝
          </el-table-column>
        <el-table-column prop="amount" label="金额" width="100">
          <template slot-scope="scope">
            {{'￥'+scope.row.amount/100 }}
          </template>
        </el-table-column>
        <el-table-column prop="payed" label="支付状态" width="100">
          <template v-slot="scope">
            <span :class="{ 'status-completed': scope.row.payed === true, 'status-pending': scope.row.payed === false }">
              {{ scope.row.payed ? '已支付' : '未支付' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="refunded" label="退款状态" width="100">
          <template v-slot="scope">
            <span v-if="scope.row.refunded === 0" class="status-pending">待审核</span>
            <span v-if="scope.row.refunded === 1" class="status-completed">已退款</span>
            <span v-if="scope.row.refunded === 2" class="status-rejected">审核不通过</span>
            <span v-if="scope.row.refunded === -1" class="status-not-submitted"></span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="180">
          <template slot-scope="scope">
            {{ new Date(scope.row.createTime).toLocaleString() }}
          </template>
        </el-table-column>
        <el-table-column prop="payed" label="申请退款" width="100">
          <template v-slot="scope">
            <el-button v-if="scope.row.payed && scope.row.refunded === -1" type="text" @click="handleRefund(scope.row.id)">退款</el-button>
          </template> 
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        :total="total"
        layout="prev, pager, next"
        class="pagination"
      >
      </el-pagination>
    </div>
  </template>
  
  <script>
  export default {
    name: 'OrderTable',
    props: {
      orders: {
        type: Array,
        required: true
      },
      total: {
        type: Number,
        required: true
      },
      currentPage: {
        type: Number,
        required: true
      },
      pageSize: {
        type: Number,
        required: true
      }
    },
    methods: {
      handleCurrentChange(newPage) {
        this.$emit('page-change', newPage);
      },
      parseOrderDetail(staticJson) {
        try {
          const parsedJson = JSON.parse(staticJson);
          //将staticJson拼接成字符串返回
          let str = ''; 
          for (let key in parsedJson) {
            str += `${key}: ${parsedJson[key]} `;
          }
          return str;
        } catch (error) {
          return staticJson;
        }
      },
      handleRefund(id) {
        this.$emit('refund-order', id);
      }
    }
  };
  </script>
  
  <style scoped>
  .pagination {
    margin-top: 20px;
    text-align: center;
    border: none;
  }
  
  .status-completed {
    color: #67c23a;
  }
  
  .status-pending {
    color: #e6a23c;
  }
  </style>