<template>
  <div class="homeBox">
    <div class="home">
      <main>
        <!-- <section class="category-section">
          <div class="category-list">
            <i class="iconfont icon-category"></i>
            <ul>
              <li v-for="category in categories" :key="category.id">
                <router-link :to="{ name: 'Category', params: { categoryId: category.id } }" class="category-item">
                  {{ category.name }}
                </router-link>
              </li>
            </ul>
          </div>
          <router-link to="/category" class="view-all">
            <div class="allRight">
              <div>全部</div>
              <img src="../assets/right.png" alt="">
            </div>
          </router-link>
        </section> -->
        <!-- <div class="banner">
        </div> -->

        <section class="bannerTop">
          <!-- <div class="bannerBox"> -->
          <div class="bannerTopLog">
            <div class="leftImg">
              <img src="../assets/yop.png" alt="">
            </div>
            <div class="twoImg">
              <div>
                <img src="../assets/日漫.jpg" alt="">
              </div>
              <div>
                <img src="../assets/冒险.jpg" alt="">
              </div>
            </div>
          </div>
          <div class="rank">
            <div class="rankName">人气榜</div>
            <div class="rankList" v-for="item, index in rankItems.slice(0, 5)" :key="index">
              <router-link :to="{ name: 'DetailPage', params: { id: item.id } }">
                <img :src="item.cover" alt="" srcset="">
                <div class="number">{{ index + 1 }}. </div>
                <div class="listHover">{{ item.name }}</div>
              </router-link>
            </div>
          </div>
          <!-- </div> -->
        </section>
        <!-- 热门 -->
        <section class="manga-section">
          <div class="mangeBox">
            <div class="mangaList">
              <div class="mangaItem" v-for="manga in premiumList" :key="manga.id">
                <router-link :to="{ name: 'DetailPage', params: { id: manga.id } }">
                  <!-- <div class="pic-tag-top">热门</div> -->
                  <img :src="manga.cover" :alt="manga.title">
                  <h4>{{ manga.name }}</h4>
                  <p v-if="manga.descr">{{ truncateDescription(manga.descr) }}</p>
                  <span class="rating" v-if="manga.dyNews">更新至：{{ manga.dyNews }}</span>
                </router-link>
              </div>
            </div>
          </div>
        </section>
        <!-- 独家 -->
        <!-- <section class="manga-section">
          <div class="mangeBox">
            <div class="mangaList">
              <div class="mangaItem" v-for="manga in privateList" :key="manga.id">
                <router-link :to="{ name: 'DetailPage', params: { id: manga.id } }">
                  <div class="pic-tag-top">独家</div>
                  <img :src="manga.cover" :alt="manga.title">
                  <h4>{{ manga.name }}</h4>
                  <p v-if="manga.descr">{{ truncateDescription(manga.descr) }}</p>
                  <span class="rating" v-if="manga.dyNews">更新至：{{ manga.dyNews }}</span>
                </router-link>
              </div>
            </div>
          </div>
        </section> -->

        <!-- 签约 -->
        <!-- <section class="manga-section  signSection">
          <div class="mangeBox">
            <div class="mangaList">
              <div class="mangaItem" v-for="manga in signList" :key="manga.id">
                <router-link :to="{ name: 'DetailPage', params: { id: manga.id } }">
                  <div class="pic-tag-top">签约</div>
                  <img :src="manga.cover" :alt="manga.title">
                  <h4>{{ manga.name }}</h4>
                  <p v-if="manga.descr">{{ truncateDescription(manga.descr) }}</p>
                  <span class="rating" v-if="manga.dyNews">更新至：{{ manga.dyNews }}</span>
                </router-link>
              </div>
            </div>
          </div>
        </section> -->

        <!-- 资费 -->
        <!-- <section class="manga-section vipSection">
          <div class="mangeBox">
            <div class="mangaList">
              <div class="mangaItem" v-for="manga in vipList" :key="manga.id">
                <router-link :to="{ name: 'DetailPage', params: { id: manga.id } }">
                  <div class="pic-tag-top">资费</div>
                  <img :src="manga.cover" :alt="manga.title">
                  <h4>{{ manga.name }}</h4>
                  <p v-if="manga.descr">{{ truncateDescription(manga.descr) }}</p>
                  <span class="rating" v-if="manga.dyNews">更新至：{{ manga.dyNews }}</span>
                </router-link>
              </div>
            </div>
          </div>
        </section> -->
      </main>
    </div>
    <section class="bannerTopTwo2">
      <div class="bannerTopLogTwo2">
        <img src="../assets/more.jpg" alt="">
      </div>
    </section>
    <section class="bannerTopTwo">
      <div class="bannerTopLogTwo">
        <img src="../assets/b.png" alt="">
      </div>
    </section>
  </div>
</template>

<script>
import apiService from '@/api/api';
export default {
  name: 'Home',
  data() {
    return {
      premiumList: [],
      privateList: [],
      signList: [],
      vipList: [],
      vipListLeft: [],
      categories: [],
      scrollAmount: 250, // 每次滚动的像素量
      privateRandomManga: null,
      premiumListRandomManga: null,
      showArrows: false,
      rankItems: [],
    }
  },
  computed: {
    bannerInputValue() {
      return this.$store.getters.bannerInputValue;
    }
  },
  watch: {
    bannerInputValue(newValue) {

      this.fetchHomeData(newValue);
    }
  },
  methods: {
    updateInputValue() {
      this.$store.dispatch('updateBannerInputValue', this.inputValue);
    },
    async rankEvt() {
      const response = await apiService.getRankList({ type: 0 });
      if (response.data.code === 0) {
        this.rankItems = response.data.data[0];
      }
    },
    fetchCategories() {
      apiService.getCategory().then(response => {
        if (response.data.code === 0) {
          this.categories = response.data.data[0].category;
        }
      });

    },
    fetchHomeData(na) {
      console.log(na, 'name');
      const params = {
        name: na || ''
      };

      console.log('你只需没有');


      apiService.getHomeData2(params).then(response => {
        if (response.data.code === 0) {
          this.premiumList = response.data.data;
          // this.premiumList = r.premium; //热门
          // this.privateList = r.private; //独家
          // this.signList = r.sign;  //签约
          // this.vipList = r.vip; //资费
        }
      });
    },
    truncateDescription(descr) {
      //增加一个判断，如果descr是null，则返回空字符串
      if (descr == null) {
        return '';
      }
      if (descr.length > 10) {
        return descr.substring(0, 10) + '...';
      }
      return descr;
    },
  },

  mounted() {
    this.fetchHomeData();
    this.fetchCategories();
    this.rankEvt()
  }
}
</script>

<style scoped>
.home {
  font-family: Arial, sans-serif;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 20px;
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.logo img {
  height: 40px;
}

.search-bar {
  display: flex;
}

.search-bar input {
  padding: 5px 10px;
  width: 300px;
}

.search-bar button {
  padding: 5px 15px;
  background-color: #7fc63a;
  color: white;
  border: none;
  cursor: pointer;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-avatar {
  width: 30px;
  height: 30px;
  background-color: #ddd;
  border-radius: 50%;
  margin-right: 10px;
}

.login-btn {
  background-color: #7fc63a;
  color: white;
  padding: 5px 10px;
  text-decoration: none;
  border-radius: 3px;
}

nav ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  background-color: #f0f0f0;
}

nav ul li {
  padding: 10px 20px;
}

nav ul li a {
  text-decoration: none;
  color: #333;
}

nav ul li a.active {
  color: #7fc63a;
}

.banner {
  height: 300px;
  background-color: #ddd;
  margin: 20px 0;
}

.category-tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.manga-section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.mangaHeader {
  display: flex;
  align-items: end;
  justify-content: space-between;
}

.manga-section-header .headerBox {
  font-size: 24px;
  color: #333;
  margin-right: 10px;
  cursor: default;
}

.cartoon-sub-title {
  font-size: 14px;
  color: #999;
  cursor: default;
}

.tag {
  background-color: #f0f0f0;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  text-decoration: none;
  color: #333;
  border-radius: 3px;
}

.category-section {
  /* display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 20px;
  border-bottom: 1px solid #f0f0f0; */
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  line-height: 48px;
  border: 1px solid #f9f9f9;
  color: #333;
  font-size: 16px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 0 5px;
  margin-bottom: 27px;
  font-size: 16px;
  box-shadow: 0 0 12px 0 rgba(221, 221, 221, .4);
}

.category-list {
  display: flex;
  align-items: center;
  font-size: 16px;
  justify-content: space-between;
}

.category-list ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}

.category-list ul li {
  margin-right: 10px;
  /* margin-bottom: 10px; */
  list-style: none;
}

.category-list ul li a {
  text-decoration: none;
  color: #333;
  list-style: none;
}

.manga-section {
  margin-bottom: 30px;
}

.manga-section h2 {
  border-left: 4px solid #7fc63a;
  padding-left: 10px;
}

.manga-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}

.manga-item {
  text-align: center;
  font-size: 12px;
}

.pic-tag-top {
  position: absolute;
  z-index: 99;
  margin-left: 28px;
  margin-top: 10px;
  padding: 2px 5px;
  font-size: 12px;
  color: #fff;
  border-radius: 2px;
  background-color: #5bb7fe
}

.manga-item a {
  text-decoration: none;
  color: #fff;
}

.manga-item img {
  width: 200px;
  height: auto;
}

.manga-item h4 {
  font-size: 14px;
  color: #333;
  margin-top: 10px;
  margin-bottom: 0px;
  padding: 0;
}

.manga-item span {
  font-size: 10px;
}

.rating {
  background-color: #7fc63a;
  padding: 2px 5px;
  border-radius: 3px;
}

.manga-list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}

.manga-list .manga-item {
  display: flex;
  text-align: left;
}

.manga-list .manga-item img {
  width: 80px;
  height: 120px;
  margin-right: 10px;
}

.more-link {
  display: block;
  text-align: right;
  color: #666;
  font-size: 16px;
  text-decoration: none;
}

footer {
  margin-top: 40px;
  text-align: center;
  color: #666;
  font-size: 14px;
}

.footer-links {
  margin-bottom: 10px;
}

.footer-links a {
  margin: 0 10px;
  color: #666;
  text-decoration: none;
}

.manga-section img:hover {
  transform: scale(1.1);
  /* 鼠标悬停时放大 */
}

.allRight {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7fc63a;
}

.allRight img {
  width: 20px;
  height: 20px;
}

.mangeBox {
  display: flex;
  margin-left: 20px;
}

.mangaBig img {
  width: 400px;
  vertical-align: top;
  height: 100%;
}

.mangaBig {
  width: 400px;
  vertical-align: top;
  height: 400px;
  text-align: center;
}

.sign-grid {
  display: flex;
  align-items: center;
  /* 使内容和箭头垂直居中 */
  overflow: hidden;
  height: 503px;
}

.sign-grid .sign-item-container,
.sign-item-containerVip {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  height: 500px;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.sign-grid .sign-item-container .signListBox {
  display: flex;
}

.sign-grid .sign-item-container .signListBox h4 {
  width: 500px;
  text-align: left;
}

.sign-grid .sign-item-container .signListBox img {
  width: 185px;
  height: 220px;
}

.sign-grid .sign-item-container::-webkit-scrollbar,
.sign-item-containerVip::-webkit-scrollbar {
  display: none;
}

.sign-grid .sign-item {
  width: 232px;
  height: 232px;
  text-align: center;
  margin: 0 8px;
}

.sign-item-container,
.sign-item-containerVip {
  height: 460px;
}

.sign-grid .sign-item img {
  width: 180px;
  height: 180px;
}

.arrow {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  margin: 0 10px;

}

.mangaList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
}


.mangaList img {
  width: 200px;
  height: 200px;

}

.mangaList h4 {
  text-align: center;
}


.signvipListLeft {
  height: 300px;
  position: relative;
}

.signvipListLeft .signItemLeft0 {
  display: inline;
  width: 197.8px;
  height: 266.6px;
  left: -25.9px;
  top: 31.7px;
  visibility: visible;
  position: absolute;
  z-index: 2;
  opacity: 1;
  border-width: 0px;
}

.signvipListLeft .signItemLeft1 {
  display: inline;
  width: 200px;
  height: 280px;
  left: 103px;
  top: 10px;
  visibility: visible;
  position: absolute;
  z-index: 3;
  opacity: 1;
  border-width: 10px;
}

.signvipListLeft .signItemLeft2 {
  display: inline;
  width: 167.8px;
  height: 236.6px;
  left: 259.1px;
  top: 31.7px;
  visibility: visible;
  position: absolute;
  z-index: 2;
  opacity: 1;
  border-width: 0px;
}

.signvipListLeft .signItemLeft0 img,
.signItemLeft2 img {
  width: 167.8px;
  height: 236.6px;
}

.signvipListLeft .signItemLeft1 img {
  width: 200px;
  height: 280px;
}

.vipBox {
  width: 450px;
}

.boxBig {
  position: relative;
}

.vipBox .boxleft {
  width: 500px;
}

.vipGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.vipGrid .item {
  text-align: center;
}

.gridBigBox {
  position: absolute;
  right: 0;
  top: 0;
}

.gidBox {
  position: absolute;
  margin: 0 -2.5px;
  float: right;
  width: 688px;
  z-index: 2;
}

.vipGrid .item img {
  width: 160px;
  height: 160px;
}


.signSection {
  margin-bottom: 40px;
}

.bannerTop {
  height: 300px;
  margin-bottom: 70px;
  display: flex;
}

.bannerTopLog {
  /* border: 1px solid red;  */
  height: 100%;
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  /* position: relative; */
}


.bannerTop .bannerBox {
  display: flex;
}

.bannerTopLog {
  height: 100%;
  width: 70%;
}

.bannerTop .bannerTopLog .leftImg img {
  height: 324px;
  width: 572px;
  padding-right: 10px;
}

.bannerTop .bannerTopLog .twoImg img {
  width: 286px;
  height: 162px;
}

.bannerTop .rank {
  width: 30%;
  margin-left: 120px;

}

.bannerTop .rank .rankName {
  width: 100%;
  display: inline-block;
  font-size: 24px;
  color: #1a1a1a;
  font-weight: normal;
  vertical-align: middle;
  margin-bottom: 10px;
  padding-bottom: 15px;
  border-bottom: 2px solid #c3392f
}

.bannerTop .rank .rankList span {
  display: inline-block;
  width: 11px;
  height: 15px;
  background: url(../assets/new-ico.png) no-repeat -68px -143px;
}

.bannerTop .rank .rankList {
  color: #4c4c4c;
  font-size: 16px;

}

.bannerTop .rank .rankList .number {
  margin-bottom: -5px;
}

.bannerTop .rank .rankList a {
  display: flex;
  justify-content: start;
  align-items: center;
  border-bottom: 1px dashed #ededed;
  margin-bottom: 5px;
}

.bannerTop .rank .rankList a img {
  width: 80px;
  height: 50px;
  overflow: hidden;
  padding-right: 10px;
}

.bannerTop .rank .rankList .listHover {
  white-space: nowrap;
  /* 防止文本换行 */
  overflow: hidden;
  /* 隐藏超出容器范围的内容 */
  text-overflow: ellipsis;
  /* 超出部分显示省略号 */
}

.bannerTop .rank .rankList .listHover:hover {
  color: red;
}

.bannerTopTwo {
  height: 80px;
  /* margin-bottom: 30px; */
}

.bannerTopLogTwo {
  /* border: 1px solid red;  */
  height: 100%;
  width: 100%;
  position: relative;
}

.bannerTopTwo .bannerTopLogTwo img {
  width: 100%;
  height: 80px;
  /* position: absolute;
  left: 55%;
  top: 50%;
  transform: translate(-50%,-50%); */
}

.bannerTopTwo2 {
  height: 150px;
  /* margin-bottom: 30px; */
}

.bannerTopLogTwo2 {
  /* border: 1px solid red;  */
  height: 100%;
  width: 100%;
  position: relative;
}

.bannerTopTwo2 .bannerTopLogTwo2 img {
  width: 100%;
  height: 150px;
  /* position: absolute;
  left: 55%;
  top: 50%;
  transform: translate(-50%,-50%); */
}
</style>