<template>
  <div class="login-page">
    <div class="login-container">
      <h2>用户登录</h2>
      <form @submit.prevent="handleLogin">
        <span class="error-message" v-if="loginError">{{ loginError }}</span>
        <div class="input-group">
          <input type="text" v-model="username" placeholder="手机号" required @blur="validatePhone">

          <span v-if="phoneError" class="error-message">{{ phoneError }}</span>
        </div>
        <div class="input-group">
          <input type="password" v-model="password" placeholder="密码" required>
        </div>
        <div class="options">
          <label>
            <input type="checkbox" v-model="rememberMe">
            记住我
          </label>
          <a href="/reset-password" class="forget-password">忘记密码？</a>
        </div>
        <button type="submit" class="login-btn">登录</button>
      </form>
      <div class="register-link">
        没账号 <a href="/register">快速注册></a>
      </div>
    </div>
  </div>
</template>

<script>

import { validatePhoneNumber } from '@/utils/validators';
import { mapActions } from 'vuex';
export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
      rememberMe: false,
      phoneError: '',
      loginError: ''
    }
  },


  methods: {
    ...mapActions(['login']),
    async handleLogin() {
      try {
        await this.login({ username: this.username, password: this.password });
        this.$router.push('/user');
      } catch (error) {
        this.loginError = error
      }
    },
    validatePhone() {
      this.phoneError = validatePhoneNumber(this.username);
    }
}}

</script>

<style scoped>
.error-message {
    color: #ff4d4f; /* 警告色 */
    font-size: 14px;
    margin-top: 5px;
    display: block;
}

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 80vh; */
  /* background-color: #f5f5f5; */
}

.login-container {
  background-color: white;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 300px;
}

h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.input-group {
  margin-bottom: 15px;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  font-size: 14px;
}

.forget-password {
  color: #666;
  text-decoration: none;
}

.login-btn {
  width: 100%;
  padding: 10px;
  background-color: #7fc63a;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
}

.login-btn:hover {
  background-color: #ff5252;
}

.register-link {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}

.register-link a {
  color: #7fc63a;
  text-decoration: none;
}
</style>